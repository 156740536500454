
import { Component, Vue } from 'vue-property-decorator'
import { AreaList } from '@/types/assetScheduling'
import AddArea from '@/views/assetScheduling/setting/AddArea.vue'

@Component({
  name: 'Area',
  components: { AddArea }
})
export default class Area extends Vue {
  private showDialog = false
  private id = ''
  private tableData: AreaList[] =[]
  private info= {
    assetLocationNameCode: ''
  }

  private page = 1
  private size = 10
  private total = 0
  private loading = false
  created () {
    this.getData()
  }

  getData () {
    this.loading = true
    this.$axios.get(this.$apis.assetScheduling.selectAssetLocationByPage, {
      ...this.info,
      page: this.page,
      size: this.size
    }).then(res => {
      this.total = res.total || 0
      this.tableData = res.list || []
    }).finally(() => {
      this.loading = false
    })
  }

  onSearch () {
    this.page = 1
    this.getData()
  }

  onAdd () {
    this.showDialog = true
  }

  onEdit (id: string) {
    this.id = id
    this.showDialog = true
  }

  success () {
    this.getData()
    this.id = ''
  }

  onUse (assetLocation: string) {
    this.$axios.post(this.$apis.assetScheduling.updateAssetLocationStatus, { assetLocation }).then(() => {
      this.$message.success('设置成功')
      this.getData()
    }).finally(() => {
      this.loading = false
    })
  }

  onDelete (assetLocation: string) {
    this.$confirm('确认删除吗, 是否继续?', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(() => {
      this.loading = true
      this.$axios.post(this.$apis.assetScheduling.deleteAssetLocation, { assetLocation }).then(() => {
        this.$message.success('删除成功')
        this.getData()
      }).finally(() => {
        this.loading = false
      })
    })
  }
}
