
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { ElForm } from 'element-ui/types/form'
import { AreaList, AreaTree } from '@/types/assetScheduling'
import AMapLoader from '@amap/amap-jsapi-loader'
import { mapKey } from '@/utils/config'
import LocationImg from '@/assets/img/icon_address.png'
@Component
export default class AddTypes extends Vue {
  @Prop({ default: false }) private showDialog!: boolean
  @Prop({ default: '' }) private id!: string
  private formInfo: AreaList = this.formInfoBak
  private list: AreaTree[] = []
  private props = {
    children: 'childNode',
    label: 'assetLocationName',
    value: 'assetLocation'
  }

  get formInfoBak () {
    return {
      assetLocationCode: '',
      assetLocationName: '',
      assetUpId: '1',
      assetLocationAddress: '',
      longitude: '',
      latitude: '',
      remarks: ''
    }
  }

  private validtorode = (rule: any, value: string, callback: Function) => {
    const reg = /^(?![0-9]+$)(?![A-Z]+$)[0-9A-Z]{4,10}$/
    if (value && !reg.test(value)) {
      return callback(new Error('请输入4-10位数字和大写字母组合'))
    } else {
      return callback()
    }
  }

  private showMap = false
  private map: any = null
  private marker: Array<any> = []
  info = {
    longitude: '',
    latitude: '',
    deviceNumber: ''
  }

  siteName = ''

  @Watch('id')
  getId (val: string) {
    val && this.getDetail(val)
  }

  @Watch('showDialog')
  getShowDialog () {
    this.getList()
  }

  private rules = {
    assetLocationCode: [
      { required: true, message: '请输入位置编码', trigger: ['blur', 'change'] },
      { validator: this.validtorode, trigger: ['blur', 'change'] }
    ],
    assetLocationName: [
      { required: true, message: '请输入位置名称', trigger: ['blur', 'change'] }
    ]
  }

  private submitShow = false
  private lnglat = {
    lng: '',
    lat: ''
  }

  // 查询二级类型
  getList () {
    this.$axios.get(this.$apis.assetScheduling.selectAssetLocationByList, {
      assetLocationExist: this.id || '',
      level: 1
    }).then(res => {
      this.list = res || []
    })
  }

  getDetail (val: string) {
    this.$axios.get(this.$apis.assetScheduling.selectAssetLocationByAssetLocation, { assetLocation: val }).then(res => {
      this.formInfo = res || []
    })
  }

  changeType (val: string) {
    this.formInfo.assetUpId = val || '1'
  }

  onSubmit () {
    ;(this.$refs.formInfo as ElForm).validate(valid => {
      if (valid) {
        this.submitShow = true
        this.$axios.post(this.id ? this.$apis.assetScheduling.updateAssetLocation : this.$apis.assetScheduling.insertAssetLocation, this.formInfo).then(() => {
          this.$message.success('保存成功')
          this.closeDialog()
          this.$emit('success')
        }).finally(() => {
          this.submitShow = false
        })
      }
    })
  }

  closeDialog () {
    this.formInfo = this.formInfoBak
    this.siteName = ''
    this.$nextTick(() => {
      (this.$refs.formInfo as ElForm).resetFields()
    })
    this.$emit('update:showDialog', false)
    this.$emit('success')
  }

  // 初始化地图
  initMap () {
    if (this.map) {
      this.formInfo.longitude && this.map.setCenter([this.formInfo.longitude, this.formInfo.latitude])
      this.addMarker(+this.formInfo.longitude, +this.formInfo.latitude)
    } else {
      AMapLoader.load({
        key: mapKey,
        version: '2.0'
      }).then((AMap) => {
        const center = this.info.longitude ? [this.info.longitude, this.info.latitude] : []
        this.map = new AMap.Map('map', {
          zoom: 15,
          ...center
        })
        this.addMarker(+this.formInfo.longitude, +this.formInfo.latitude)
        this.map.on('click', (e: any) => {
          this.formInfo.assetLocationAddress = ''
          this.siteName = ''
          this.lnglat.lng = e.lnglat.lng
          this.lnglat.lat = e.lnglat.lat
          this.addMarker(e.lnglat.lng, e.lnglat.lat)
        })

        // 输入提示
        const autoOptions = {
          input: 'tipinput'
        }
        AMap.plugin(['AMap.PlaceSearch', 'AMap.AutoComplete'], () => {
          const auto = new AMap.Autocomplete(autoOptions)
          auto.on('select', (e: any) => {
            this.lnglat.lng = e.poi.location.lng
            this.lnglat.lat = e.poi.location.lat
            this.map.setZoomAndCenter(16, [this.lnglat.lng, this.lnglat.lat])
            this.addMarker(+this.lnglat.lng, +this.lnglat.lat)
          })
        })
      })
    }
  }

  addMarker (lng: number, lat: number) {
    this.map.remove(this.marker)
    if (lng && lat) {
      this.marker = []
      const marker: any = new AMap.Marker({
        map: this.map,
        position: new AMap.LngLat(lng, lat),
        offset: new AMap.Pixel(-20, -10),
        icon: LocationImg
      })
      marker.setLabel({
        direction: 'top',
        offset: new AMap.Pixel(0, -5)
      })
      this.marker.push(marker)
      this.map.add(marker)
    }
  }

  handleClose () {
    this.showMap = false
    this.map && this.map.remove(this.marker)
  }

  onDelete () {
    this.formInfo.assetLocationAddress = ''
    this.formInfo.longitude = ''
    this.formInfo.latitude = ''
  }

  saveMap () {
    this.formInfo.longitude = this.lnglat.lng
    this.formInfo.latitude = this.lnglat.lat
    if (!this.formInfo.longitude) {
      this.$message.warning('请先选择位置坐标！')
      return false
    }
    this.showMap = false
    // 搜索地址
    AMap.plugin('AMap.Geocoder', () => {
      // 根据所选区域初始化项目经纬度
      const geocoder = new AMap.Geocoder()
      geocoder.getAddress([Number(this.formInfo.longitude), Number(this.formInfo.latitude)], (status: any, result: any) => {
        if (status === 'complete' && result.info === 'OK') {
          this.formInfo.assetLocationAddress = result.regeocode.formattedAddress
        }
      })
    })
  }

  openMap () {
    this.showMap = true
    this.initMap()
  }

  closeMap () {
    this.showMap = false
    this.siteName = ''
  }
}
